<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        d-flex
        flex-row-fluid flex-column
        bgi-size-cover bgi-position-center bgi-no-repeat
        p-10 p-sm-30
      "
      :style="{
        backgroundImage: `url(${backgroundImage})`,
      }"
    >
      <!-- begin:: Content -->
      <h3
        class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
        style="font-size: 150px"
      >
        Sukses
      </h3>
      <p class="font-size-h3 font-weight-light">
        Pembayaran anda telah kami terima
      </p>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
import moduleSaas from "@/core/modules/CrudSaasModule.js";

export default {
  name: "Error-1",
  mounted() {
    this.setLocalstorage();
  },
  methods: {
    async setLocalstorage() {
      let url = "";
      if (process.env.NODE_ENV === "production") {
        // HOST
        url = window.location.href;
      } else {
        // Local
        // url = "https://mitrahub.medhop.id";
        url = "https://mawar.medhop.id";
      }

      let domain = new URL(url);
      domain = domain.hostname;
      let response = await moduleSaas.getDbConfig(
        `get-db-config?domain=${domain}`
      );

      console.log(response, "--- test ========");
      if (response != null) {
        localStorage.setItem("tenantconfig", JSON.stringify(response));
        this.$root.$emit("subUpdate");

        setTimeout(() => {
          this.$router.push("/dashboard");
        }, 7000);
      }
    },
  },
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/subscribe/subscribe-confirmation.png"
      );
    },
  },
};
</script>
